import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const StarIcon = (props: IIconProps) => {
  const { height = 78, width = 78, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.0432 2.24533C37.6298 0.503912 40.3702 0.503909 41.9568 2.24533L45.5552 6.1948C46.6077 7.35001 48.2366 7.78647 49.7257 7.31228L54.8167 5.69112C57.0614 4.9763 59.4347 6.34651 59.938 8.64792L61.0795 13.8674C61.4134 15.3941 62.6059 16.5866 64.1326 16.9205L69.3521 18.062C71.6535 18.5653 73.0237 20.9386 72.3089 23.1833L70.6877 28.2743C70.2135 29.7634 70.65 31.3923 71.8052 32.4448L75.7547 36.0432C77.4961 37.6298 77.4961 40.3702 75.7547 41.9568L71.8052 45.5552C70.65 46.6077 70.2135 48.2366 70.6877 49.7257L72.3089 54.8167C73.0237 57.0614 71.6535 59.4347 69.3521 59.938L64.1326 61.0795C62.6059 61.4134 61.4134 62.6059 61.0795 64.1326L59.938 69.3521C59.4347 71.6535 57.0614 73.0237 54.8167 72.3089L49.7257 70.6877C48.2366 70.2135 46.6077 70.65 45.5552 71.8052L41.9568 75.7547C40.3702 77.4961 37.6298 77.4961 36.0432 75.7547L32.4448 71.8052C31.3923 70.65 29.7634 70.2135 28.2743 70.6877L23.1833 72.3089C20.9386 73.0237 18.5653 71.6535 18.062 69.3521L16.9205 64.1326C16.5866 62.6059 15.3941 61.4134 13.8674 61.0795L8.64793 59.938C6.34651 59.4347 4.9763 57.0614 5.69112 54.8167L7.31228 49.7257C7.78647 48.2366 7.35001 46.6077 6.1948 45.5552L2.24533 41.9568C0.503912 40.3702 0.503909 37.6298 2.24533 36.0432L6.1948 32.4448C7.35001 31.3923 7.78647 29.7634 7.31228 28.2743L5.69112 23.1833C4.9763 20.9386 6.34651 18.5653 8.64792 18.062L13.8674 16.9205C15.3941 16.5866 16.5866 15.3941 16.9205 13.8674L18.062 8.64793C18.5653 6.34651 20.9386 4.9763 23.1833 5.69112L28.2743 7.31228C29.7634 7.78647 31.3923 7.35001 32.4448 6.1948L36.0432 2.24533Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default React.memo(StarIcon);
