import React from 'react';

import StarIcon from '../../images/Star';

import style from './Hero.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

interface IHeroProps {
  className?: string;
  children?: React.ReactNode;
  title: string;
  description: string;
  tag?: {
    name: string;
    slug: string;
  };
  icon?: React.ReactNode;
}

export const Hero = (props: IHeroProps) => (
  <div className={cn(props.className, style.hero)}>
    <div className="container">
      {props.tag && (
        <Link to={`/tag/${props.tag.slug}/`} className={style.tagWrapper}>
          <small className={style.tags}>{props.tag.name}</small>
        </Link>
      )}
      {props.icon && (
        <div className={style.icon}>
          <StarIcon className={style.star} />
          {props.icon}
        </div>
      )}
      <h1>{props.title}</h1>
      <p dangerouslySetInnerHTML={{ __html: props.description }} />
    </div>
  </div>
);
